.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

body {
  background-color: #f9f7f2;
  font-family: 'Poppins', sans-serif;
}

.lemon {
  position: absolute;
  z-index: 0;
}

.lemon-1 {
  bottom: 0%;
  left: 0%;
}

.lemon-2 {
  top: 0%;
  left: 5%;
}

.lemon-3 {
  bottom: 0%;
  right: 0%;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.login-container {
  position: relative;
  z-index: 1;
  width: 450px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 14px;
  background-color: #ffffff;
}

.login-logo-container {
  text-align: center;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.login-logo {
  width: 400px;
}

.login-form-container {
  display: flex;
  flex-direction: column;
}

.login-title {
  color: #2d3647;
  font-size: 1.3rem;
  font-weight:bold;
  margin-bottom: 2rem;
  text-align: center;
}

.login-label {
  display: none;
}

.login-form-card {
  background: #ffffff;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-bottom: 2rem;
  border-radius: 10px;
}

.login-input {
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  border: 1px solid transparent;
  border-radius: 10; /* Remove border-radius to have square inputs */
}

.login-input:focus {
  outline: none; /* Removes the default focus outline */
}

.login-input::placeholder {
  color: #dddddd;
  font-weight: bold;
  padding-left: 0;
}

.login-input:first-child {
  border-radius: 8px 8px 0 0; /* Only top corners rounded */
  border-bottom: 1px solid transparent; /* Transparent bottom border to maintain the input height */
}

.login-input:first-child + .login-input {
  border-radius: 0 0 8px 8px; /* Only bottom corners rounded */
  border-top: 1px solid #d3d3d3; /* Light grey line for the top border */
  margin-top: -1px; /* Overlap the bottom border of the first input */
}

.login-input:last-child {
  border-top: none; 
  border-radius: 0 0 8px 8px; 
}

.login-button {
  width: 100%;
  padding: 0.5rem;
  background-color: #1c6941;
  color: white;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #1c6941c5;
}

.login-footer {
  position: absolute;
  bottom: 3%; /* Adjust based on Figma position */
  left: 2%; /* Adjust based on Figma position */
  background-color: transparent;
  color: #2d3647;
  font-size: 1rem;
  z-index: 1;
}

.nav-link {
  cursor: pointer;
  font-size: 18px;
  margin: 0 32px; /* Adjust spacing between links */
  color: black !important;
  text-decoration: none;
  position: relative;
  padding: 0 1px !important; /* Adjust padding to ensure underline matches text width */
  transition: color 0.3s !important; /* Smooth hover effect */
}

.nav-link:hover {
  color: #000000 !important;
}

.nav-link.active {
  color: #000000 !important;
}

.nav-link .underline {
  content: '';
  position: absolute;
  width: 100%; /* Ensure underline matches text width */
  height: 2px;
  background-color: #000000;
  bottom: -2px; /* Position the underline slightly below the text */
  left: 0;
}

.navigation-links {
  display: flex;
  justify-content: center; /* Center links horizontally */
  align-items: center; /* Center links vertically */
  width: 100%;
}

.refresh-button {
  transition: border-color 0.3s, background-color 0.3s; /* Smooth transition for hover effect */
}

.refresh-button:hover {
  background-color: #1c6941c5 !important; /* Darker shade for the background */
  border-color: #1c6941c5 !important; /* Darker shade for the background */
}

.signout-button {
  transition: border-color 0.3s, background-color 0.3s; /* Smooth transition for hover effect */
}

.signout-button:hover {
  background-color: #5F9847 !important; /* Darker shade for the background */
  border-color: #5F9847 !important; /* Darker shade for the background */
}